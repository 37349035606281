import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import HomeQueryForm from '@/components/home-query-form';
import { Card, Tabs, Row, Col, Table, Space, Tag } from 'antd';
const { Meta } = Card;
function Events() {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);
    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [])

  const initChart = (chartInstance) => {
    chartInstance.setOption(option);
  }

  const option = {
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['00:00 - 00:59', '01:00 - 01:59', '02:00 - 02:59', '03:00 - 03:59', '04:00 - 04:59', '05:00 - 05:59', '06:00 - 06:59', '07:00 - 07:59', '08:00 - 08:59', '09:00 - 09:59', '10:00 - 10:59', '11:00 - 11:59', '12:00 - 12:59', '13:00 - 13:59', '14:00 - 14:59', '15:00 - 15:59', '16:00 - 16:59', '17:00 - 17:59', '18:00 - 18:59', '19:00 - 19:59', '20:00 - 20:59', '21:00 - 21:59', '22:00 - 22:59', '23:00 - 23:59']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [38,4,15,4,0,6,6,10,35,139,138,35,52,82,82,86,54,73,30,22,23,18,25,25],
        type: 'line',
        areaStyle: {
          color: 'rgba(24, 144, 255, 0.3)'
        }
      }
    ]
  }
  return (
    <Card title="事件分析">
      <HomeQueryForm />

      <Tabs
        style={{ marginTop: 20 }}
        defaultActiveKey="1"
        type='card'
        items={[
          { label: '全部事件', key: '1' },
          { label: '类型', key: '2' },
          { label: '操作', key: '3' },
          { label: '标签', key: '4' }
        ]}
      ></Tabs>

      <Row gutter={20} style={{ marginTop: 30 }}>
        <Col span={6}>
          <Card bordered={false}>
            <Meta title="事件总数" description="1002" />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Meta title="唯一访客事件数" description="583" />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Meta title="事件价值" description="0" />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Meta title="平均价值" description="0" />
          </Card>
        </Col>
      </Row>

      <div>
        <div ref={chartRef} className='chart-line' style={{ height: 286 }}></div>
      </div>

      <Table
        size='small'
        bordered
        columns={[
          {
            title: '',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => index + 1
          },
          {
            title: '全部事件(类型+操作+标签)',
            dataIndex: 'event',
            key: 'event',
            render: (text, record) => (
              <Space>
                <Tag color='blue'>{record.type}</Tag>
                <Tag color='green'>{record.op}</Tag>
                <Tag color='orange'>{record.tag}</Tag>
              </Space>
            )
          },
          {
            title: '事件总数',
            dataIndex: 'count',
            key: 'count'
          },
          {
            title: '唯一访客事件数',
            dataIndex: 'unique',
            key: 'unique'
          },
          {
            title: '事件价值',
            dataIndex: 'value',
            key: 'value'
          },
          {
            title: '平均价值',
            dataIndex: 'avg',
            key: 'avg'
          }
        ]}
        dataSource={[
          { type: '类型1', op: '操作1', tag: '标签1', count: 100, unique: 50, value: 0, avg: 0 },
          { type: '类型2', op: '操作2', tag: '标签2', count: 200, unique: 100, value: 0, avg: 0 },
          { type: '类型3', op: '操作3', tag: '标签3', count: 300, unique: 150, value: 0, avg: 0 },
          { type: '类型4', op: '操作4', tag: '标签4', count: 400, unique: 200, value: 0, avg: 0 },
          { type: '类型5', op: '操作5', tag: '标签5', count: 500, unique: 250, value: 0, avg: 0 },
          { type: '类型6', op: '操作6', tag: '标签6', count: 600, unique: 300, value: 0, avg: 0 },
          { type: '类型7', op: '操作7', tag: '标签7', count: 700, unique: 350, value: 0, avg: 0 },
          { type: '类型8', op: '操作8', tag: '标签8', count: 800, unique: 400, value: 0, avg: 0 },
          { type: '类型9', op: '操作9', tag: '标签9', count: 900, unique: 450, value: 0, avg: 0 },
          { type: '类型10', op: '操作10', tag: '标签10', count: 1000, unique: 500, value: 0, avg: 0 },
          { type: '类型11', op: '操作11', tag: '标签11', count: 1100, unique: 550, value: 0, avg: 0 },
          { type: '类型12', op: '操作12', tag: '标签12', count: 1200, unique: 600, value: 0, avg: 0 },
          { type: '类型13', op: '操作13', tag: '标签13', count: 1300, unique: 650, value: 0, avg: 0 },
          { type: '类型14', op: '操作14', tag: '标签14', count: 1400, unique: 700, value: 0, avg: 0 },
          { type: '类型15', op: '操作15', tag: '标签15', count: 1500, unique: 750, value: 0, avg: 0 },
          { type: '类型16', op: '操作16', tag: '标签16', count: 1600, unique: 800, value: 0, avg: 0 },
          { type: '类型17', op: '操作17', tag: '标签17', count: 1700, unique: 850, value: 0, avg: 0 },
          { type: '类型18', op: '操作18', tag: '标签18', count: 1800, unique: 900, value: 0, avg: 0 },
          { type: '类型19', op: '操作19', tag: '标签19', count: 1900, unique: 950, value: 0, avg: 0 },
          { type: '类型20', op: '操作20', tag: '标签20', count: 2000, unique: 1000, value: 0, avg: 0 }
        ]}
        pagination={
          {
            pageSize: 20,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
            showTotal: total => {
              return `共 ${total} 条`
            },
            defaultCurrent: 1,
            defaultPageSize: 20
          }
        }
      ></Table>
    </Card>
  );
};

export default Events;