import {Form, Row, Col, Radio, Space, DatePicker} from 'antd';
function HomeQueryForm() {
  return (
    <>
      <div className='all-queryForm'>
        <Form
          name="queryForm"
          layout='inline'
          style={{ width: '100%' }}
          initialValues={{
            time: 'today',
            device: 'all',
            visitor: 'all',
            shield: 'all'
          }}
        >
          <Row gutter={[20, 20]} style={{ width: '100%' }}>
            <Col span={24}>
              <Form.Item label='时间'>
                <Space>
                  <Form.Item name="time">
                    <Radio.Group buttonStyle='solid'>
                      <Radio.Button value="today">今天</Radio.Button>
                      <Radio.Button value="yesterday">昨天</Radio.Button>
                      <Radio.Button value="week">最近7天</Radio.Button>
                      <Radio.Button value="month">最近30天</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="date">
                    <DatePicker.RangePicker />
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Space>
                <Form.Item label="设备" name="device">
                  <Radio.Group buttonStyle='solid'>
                    <Radio.Button value="all">全部</Radio.Button>
                    <Radio.Button value="pc">计算机</Radio.Button>
                    <Radio.Button value="mobile">移动设备</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="访客" name="visitor">
                  <Radio.Group buttonStyle='solid'>
                    <Radio.Button value="all">全部</Radio.Button>
                    <Radio.Button value="new">新访客</Radio.Button>
                    <Radio.Button value="old">老访客</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="智能屏蔽数据" name="shield">
                  <Radio.Group buttonStyle='solid'>
                    <Radio.Button value="all">包含</Radio.Button>
                    <Radio.Button value="shield">不包含</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default HomeQueryForm;