import { useState } from 'react';
import { Table, Card, Button, Modal, Form, Input, message, Space, Popconfirm } from 'antd';

function Users() {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([
    {
      id: 1,
      username: 'admin',
      nickname: '管理员',
      phone: '13144333236',
      time: '2024-05-01 12:28:00',
      remark: '--',
      status: 1
    },
    {
      id: 2,
      username: 'dexiaoxi',
      nickname: '德小喜',
      phone: '13821104458',
      time: '2024-03-22 08:16:00',
      remark: '一般用户',
      status: 1
    },
  ]);
  const [selectItem, setSelectItem] = useState({});

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname'
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: '最近登录时间',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return record.status === 1 ? '正常' : '禁用'
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record, index) => {
        return (
          <Space>
            <Button type='primary' size='small' onClick={() => editClick(record)}>编辑</Button>
            {
              record.username !== 'admin' && (
                <Popconfirm
                  size='small'
                  title="确定要删除吗？"
                  onConfirm={() => delClick(index)}
                  onCancel={() => setVisible(false)}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button type='text' danger size='small'>删除</Button>
                </Popconfirm>
              )
            }
          </Space>
        )
      }
    }
  ]
  // 添加活动事件
  const addClick = () => {
    setVisible(true);
    form.resetFields();
  }
  // 确认添加事件
  const handleOk = () => {
    form.validateFields().then(values => {
      let data = [...tableData];
      if (selectItem.id) {
        data = data.map(item => {
          if (item.id === selectItem.id) {
            return { ...item, ...values }
          }
          return item;
        })
      } else {
        data.unshift({ id: tableData.length + 1, ...values, status: 1, time: '--' });
      }
      setTableData(data);
      message.open({
        type: 'success',
        content: '操作成功'
      })
      setVisible(false);
      form.resetFields();
    }).catch(err => {
      message.open({
        type: 'error',
        content: '请检查表单填写是否正确'
      })
    });
  }
  // 取消添加事件
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  }
  // 删除事件
  const delClick = (index) => {
    let data = [...tableData];
    data.splice(index, 1);
    setTableData(data);
    message.open({
      type: 'success',
      content: '删除成功'
    })
  }
  // 编辑事件
  const editClick = (record) => {
    setVisible(true);
    form.setFieldsValue(record);
    setSelectItem(record);
  }

  return (
    <>
      <div>
        <Card
          title="用户管理"
          extra={
            <Button type='primary' className='addBtn' onClick={addClick}>添加用户</Button>
          }
        >
          <Table
            className='news-table'
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={tableData}
          />
        </Card>

        <Modal
          title="添加用户"
          open={visible}
          destroyOnClose={true}
          onCancel={handleCancel}
          footer={
            [
              <Button key="back" onClick={handleCancel} className='modalFooterBtn'>取消</Button>,
              <Button key="submit" type="primary" onClick={handleOk} className='modalFooterBtn'>确认</Button>
            ]
          }
        >
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            preserve={false}
          >
            <Form.Item
              label="用户名"
              name="username"
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="昵称"
              name="nickname"
              rules={[{ required: true, message: '请输入昵称' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="手机号码"
              name="phone"
              rules={[
                { required: true, message: '请输入手机号码' },
                { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码' }
              ]}
            >
              <Input maxLength={11} />
            </Form.Item>
            <Form.Item
              label="备注"
              name="remark"
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
};

export default Users;