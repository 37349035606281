import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import { Card } from 'antd';
function FinancialReceipts() {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [])

  // 生成x轴数据
  const generateXData = () => {
    // 从当前月份开始，往后推6个月，格式为YYYY-MM
    const data = [];
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    for (let i = 0; i < 6; i++) {
      if (month + i > 12) {
        data.push(`${year + 1}-${month + i - 12}`);
      } else {
        data.push(`${year}-${month + i}`);
      }
    }
  }

  const initChart = (chartInstance) => {
    chartInstance.setOption({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['预估房租收款']
      },
      xAxis: {
        type: 'category',
        data: generateXData()
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '预估房租收款',
          data: [0,0,0,0,0,0],
          type: 'line'
        },
      ]
    });
  }
  return (
    <Card title="预估收款">
      <div ref={chartRef} className='chart-base' style={{height: 602}}></div>
    </Card>
  );
};

export default FinancialReceipts;