
import { Card } from 'antd';
import HomeComponent from '@/components/home-component';
import HomeQueryForm from '@/components/home-query-form';

function EnginesSource() {
 
  return (
    <>
      <Card>
        <HomeQueryForm />

        <div>
          <HomeComponent />
        </div>
      </Card>
    </>
  )
};

export default EnginesSource;