import { useState } from "react";
import { Card, Upload, Button, Tabs } from "antd";
const { Dragger } = Upload;
function DataExchange() {
  const [tabActive, setTabActive] = useState("1");
  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        type="card"
        style={{ marginBottom: 20 }}
        items={[
          {
            label: "导入文章",
            key: "1",
          },
          {
            label: "导入财务报表",
            key: "2",
          }
        ]}
      ></Tabs>
      <div>
        <i>读取文件夹导入文章，格式要求为doc/docx。</i>
      </div>
      <Dragger
        directory
        name="file"
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        style={{ padding: 20 }}
        showUploadList
      >
        <Button type="primary">把文件夹拖拽到这里，或者点击上传</Button>
      </Dragger>
    </Card>
  );
};

export default DataExchange;