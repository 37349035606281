import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';

function VisitorAge() {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [])

  const initChart = (chartInstance) => {
    chartInstance.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: '{b}: {c}%'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['18岁以下', '18-24岁', '25-34岁', '35-44岁', '45-54岁', '55岁以上'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          // 百分比显示
          axisLabel: {
            formatter: '{value}%'
          }
        }
      ],
      series: [
        {
          name: '年龄分布',
          type: 'bar',
          barWidth: '60%',
          data: [2, 16, 47, 30, 7, 5]
        }
      ]
    });
  }
  return <div ref={chartRef} className='chart-base' style={{height: 412}}></div>;
};

export default VisitorAge;