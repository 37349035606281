import dayjs from 'dayjs';
const key = 'ty-platform-backstage-token';

export function getStorage() {
  return localStorage.getItem(key);
}

export function setStorage(value) {
  return localStorage.setItem(key, value);
}

export function removeStorage() {
  return localStorage.removeItem(key);
}

// 返回今天的时间，格式为YYYY/MM/DD HH:mm:ss
export function getToday() {
  return dayjs().format('YYYY/MM/DD HH:mm:ss');
}