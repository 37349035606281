import { useState } from 'react';
import {Card, Tabs} from 'antd';
import HomeQueryForm from '@/components/home-query-form';
import HomeComponent from '@/components/home-component';
function AllSource() {
  const [tabs, setTabs] = useState('type');
  return (
    <div>
      <Card>
        
        <HomeQueryForm />
        
        <Tabs 
          style={{marginTop: '20px'}}
          type='card'
          onChange={(key) => setTabs(key)}
          items={[
            {
              label: '来源类型',
              key: 'type',
            },
            {
              label: '来源网站',
              key: 'site',
            }
          ]}
        ></Tabs>

        {
          tabs === 'type' ? <HomeComponent /> : <HomeComponent />
        }
      </Card>
    </div>
  );
};

export default AllSource;