import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';

import { Card, Row, Col, Table, Form, Select, Radio, DatePicker } from "antd";
import './real-time.scss';
import {getToday} from '@/utils';

function RealTime() {
  const chartRef = useRef(null);
  // 获取近30分钟的时间，把每分钟保存到数组中
  const time = new Date();
  const timeArr = [];
  for (let i = 0; i < 30; i++) {
    timeArr.push(time.getHours() + ':' + time.getMinutes());
    time.setMinutes(time.getMinutes() - 1);
  }


  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [])
  const initChart = (chartInstance) => {
    chartInstance.setOption({
      title: {
        text: '最近30分钟访问情况',
        textStyle: {
          fontSize: 14,
          fontWeight: 'normal',
          color: '#121315',
        },
        left: 'center',
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['浏览量(PV)', '访客数(UV)'],
        bottom: 0
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: timeArr.reverse()
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '浏览量(PV)',
          data: [150, 230, 224, 218, 135, 147, 260, 150, 230, 224, 218, 135, 147, 260, 224, 218, 135, 147, 260, 150, 230, 224, 218, 135,224, 218, 135, 147, 260, 150, 230],
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
        },
        {
          name: '访客数(UV)',
          data: [120, 82, 91, 154, 162, 140, 130, 120, 82, 91, 154, 162, 140, 130, 91, 154, 162, 140, 130, 120, 82, 91, 154, 162, 140, 130, 91, 154, 162, 140, 130],
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
        }
      ]
    });
  }
  return (
    <>
      <Card title="当前在线">
        <Row gutter={20}>
          <Col span={4}>
            <div className="real-time-top">
              <div>{getToday()}</div>
              <div className="real-time-total">919</div>
              <div>在线访客数</div>
            </div>
          </Col>
          <Col span={20}>
            <div className="real-time-top">
              <div ref={chartRef} className='chart-base' style={{ height: 412 }}></div>
            </div>
          </Col>
        </Row>
      </Card>
      <Card title="访问明细" style={{marginTop: 20}}>
        <Form layout='inline'>
          <Form.Item label="来源" name="source">
            <Select 
              allowClear
              placeholder="选择来源"
              options={[
                {
                  label: '全部来源',
                  value: 'all'
                },
                {
                  label: '直接访问',
                  value: 'direct'
                },
                {
                  label: '搜索引擎',
                  value: 'search'
                },
                {
                  label: '外部链接',
                  value: 'external'
                }
              ]}
            ></Select>
          </Form.Item>
          <Form.Item label="地域" name="area">
            <Select
              allowClear
              showSearch
              placeholder="选择地域(搜索)"
              options={[
                {
                  label: '全部地域',
                  value: 'all'
                },
                {
                  label: '北京',
                  value: 'beijing'
                },
                {
                  label: '上海',
                  value: 'shanghai'
                },
                {
                  label: '广州',
                  value: 'guangzhou'
                }
              ]}
            ></Select>
          </Form.Item>
          <Form.Item label="设备" name="device">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="all">全部</Radio.Button>
              <Radio.Button value="pc">计算机</Radio.Button>
              <Radio.Button value="mobile">移动设备</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="访客" name="guest">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="all">全部</Radio.Button>
              <Radio.Button value="new">新访客</Radio.Button>
              <Radio.Button value="old">老访客</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="时间" name="date">
            <DatePicker />
          </Form.Item>
        </Form>
        <Table
          size='small'
          style={{marginTop: 20}}
          columns={[
            {
              title: '#',
              dataIndex: 'index',
              key: 'index',
              render: (text, record, index) => index + 1
            },
            {
              title: '访问时间',
              dataIndex: 'time',
              key: 'time',
            },
            {
              title: '地域',
              dataIndex: 'area',
              key: 'area',
            },
            {
              title: '来源',
              dataIndex: 'source',
              key: 'source',
            },
            {
              title: '入口页面',
              dataIndex: 'page',
              key: 'page',
            },
            {
              title: '搜索词',
              dataIndex: 'keyword',
              key: 'keyword',
            },
            {
              title: '访问IP',
              dataIndex: 'ip',
              key: 'ip',
              width: 70
            },
            {
              title: '访客标识码',
              dataIndex: 'code',
              key: 'code'

            },
            {
              title: '访问时长',
              dataIndex: 'duration',
              key: 'duration',
            },
            {
              title: '访问页数',
              dataIndex: 'pages',
              key: 'pages',
              align: 'right'
            }
          ]}
          dataSource={[
            {
              time: '2024/06/25 13:49:45',
              area: '安阳',
              source: 'http://ty-platform.com/web/custom/event_track',
              page: 'http://ty-platform.com/sc-web/home/statrule/crossdomain',
              keyword: '-',
              ip: '222.139.23.186',
              code: '2113945974989270501',
              duration: '00:00:30',
              pages: 1
            },
            {
              time: '2024/06/25 13:49:45',
              area: '滁州',
              source: '百度',
              page: 'http://ty-platform.com/web/pro/hour',
              keyword: '直播产业链',
              ip: '223.244.8.8',
              code: '2164946258877940317',
              duration: '00:00:12',
              pages: 1
            },
            {
              time: '2024/06/25 13:49:44',
              area: '锦州',
              source: '直接访问',
              page: 'http://ty-platform.com/web/trend/latest',
              keyword: '-',
              ip: '175.166.105.9',
              code: '5490542546807237810',
              duration: '00:00:5',
              pages: 1
            },
            {
              time: '2024/06/25 13:49:39',
              area: '泰州',
              source: '百度',
              page: 'http://ty-platform.com/web/source/all',
              keyword: '直播工具',
              ip: '121.230.219.173',
              code: '2123159830322431771',
              duration: '00:00:11',
              pages: 1
            },
            {
              time: '2024/06/25 13:49:38',
              area: '湛江',
              source: '搜狗',
              page: 'http://ty-platform.com/web/visit/attribute',
              keyword: '-',
              ip: '113.110.67.230',
              code: '8642473046620478668',
              duration: '00:00:2',
              pages: 2
            },
            {
              time: '2024/06/25 13:49:37',
              area: '郑州',
              source: 'http://ty-platform.com/sc-web/home/subdir/create',
              page: 'http://ty-platform.com/web/custom/newpath',
              keyword: '-',
              ip: '115.57.150.86',
              code: '1907697779524215424',
              duration: '00:00:13',
              pages: 3
            },
            {
              time: '2024/06/25 13:49:34',
              area: '其他',
              source: '直接访问',
              page: 'http://ty-platform.com/web/visit/district',
              keyword: '-',
              ip: '220.45.5.150',
              code: '1963778417731402688',
              duration: '00:00:30',
              pages: 3
            },
            {
              time: '2024/06/25 14:07:49',
              area: '青岛',
              source: '直接访问',
              page: 'http://ty-platform.com/analytics/conversion/overview',
              keyword: '-',
              ip: '27.223.239.232',
              code: '1924875669036696878',
              duration: '00:00:05',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:48',
              area: '榆林',
              source: '百度',
              page: 'http://ty-platform.com/sc-web/home/customevent',
              keyword: '直播产业链',
              ip: '124.89.70.251',
              code: '1887838641884799123',
              duration: '00:00:03',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:48',
              area: '其他',
              source: '直接访问',
              page: 'http://ty-platform.com/web/source/all',
              keyword: '-',
              ip: '122.55.213.69',
              code: '1781232385534554480',
              duration: '00:00:02',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:46',
              area: '常州',
              source: '直接访问',
              page: 'http://ty-platform.com/analytics/funnel',
              keyword: '-',
              ip: '112.1.127.134',
              code: '5404443613804309261',
              duration: '00:00:08',
              pages: 2
            },
            {
              time: '2024/06/25 14:07:45',
              area: '其他',
              source: '直接访问',
              page: 'http://ty-platform.com/web/opt/updown',
              keyword: '-',
              ip: '101.32.202.34',
              code: '1994303321310520063',
              duration: '00:00:24',
              pages: 3
            },
            {
              time: '2024/06/25 14:07:42',
              area: '其他',
              source: '直接访问',
              page: 'http://ty-platform.com/analytics/asset',
              keyword: '-',
              ip: '154.89.4.4',
              code: '1818035608337005556',
              duration: '00:00:30',
              pages: 2
            },
            {
              time: '2024/06/25 14:07:42',
              area: '其他',
              source: '百度',
              page: 'http://ty-platform.com/web/custom/media',
              keyword: '数据分析',
              ip: '2409:895a:3f23……',
              code: '1917349400445959192',
              duration: '00:00:05',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:41',
              area: '郑州',
              source: 'http://ty-platform.com/sc-web/home/subdir/create',
              page: 'http://ty-platform.com/web/overview',
              keyword: '数据分析',
              ip: '171.81.194.86',
              code: '1917349400445959192',
              duration: '00:00:11',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:39',
              area: '大连',
              source: '直接访问',
              page: 'http://ty-platform.com/sc-web/home/js/install',
              keyword: '-',
              ip: '123.185.110.242',
              code: '1884476188569483375',
              duration: '00:00:11',
              pages: 2
            },
            {
              time: '2024/06/25 14:07:35',
              area: '北京',
              source: '直接访问',
              page: 'http://ty-platform.com/sc-web/home/account',
              keyword: '网站统计',
              ip: '221.216.245.156',
              code: '2117095491148113464',
              duration: '00:00:30',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:32',
              area: '泉州',
              source: 'http://ty-platform.com/sc-web/home/subdir/create',
              page: 'http://ty-platform.com/web/custom/subdir',
              keyword: '-',
              ip: '183.250.223.9',
              code: '1779025619114925778',
              duration: '00:00:30',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:31',
              area: '成都',
              source: '直接访问',
              page: 'http://ty-platform.com/sc-web/home/trans/event/create',
              keyword: '-',
              ip: '182.148.241.13',
              code: '1945574211670769967',
              duration: '00:00:08',
              pages: 1
            },
            {
              time: '2024/06/25 14:07:31',
              area: '北京',
              source: '百度',
              page: 'http://ty-platform.com/analytics/conversion/overview',
              keyword: '马栏山',
              ip: '219.143.154.37',
              code: '2124508162041309914',
              duration: '00:00:40',
              pages: 3
            },
          ]}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['20', '50', '100', '500'],
            defaultPageSize: 20,
            showTotal: total => `共 ${total} 条`,
            pageSize: 20
          }}
        ></Table>
      </Card>
    </>
  )
};

export default RealTime;