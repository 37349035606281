import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LayoutComponent from './components/layout';
import AuthComponent from '@/components/auth-component';

import Home from '@/pages/home'; // 网站概况
import DataAnalysis from '@/pages/home/data-analysis'; // 流量分析
import RealTime from '@/pages/home/data-analysis/children/real-time/index.js'; // 实时访客
import Trend from '@/pages/home/data-analysis/children/trend'; // 趋势分析
import SourceAnalysis from '@/pages/home/origin'; // 来源分析
import AllSource from '@/pages/home/origin/children/all'; // 全部来源
import EnginesSource from '@/pages/home/origin/children/engines'; // 搜索引擎
import SearchTerm from '@/pages/home/origin/children/search-term'; // 搜索词
import ExternalLinks from '@/pages/home/origin/children/external-links'; // 外部链接
import AccessAnalysis from '@/pages/home/access-analysis'; // 访问分析
import InterviewedPage from '@/pages/home/access-analysis/children/interviewed-page'; // 受访页面
import EntryPage from '@/pages/home/access-analysis/children/entry-page'; // 入口页面
import VisitedDomain from '@/pages/home/access-analysis/children/visited-domain'; // 受访域名
import PageClickImage from '@/pages/home/access-analysis/children/page-click-image'; // 页面点击图
import Events from '@/pages/home/access-analysis/children/events'; // 事件分析

import TransformationAnalysis from '@/pages/home/transformation-analysis'; // 转化分析
import TransformationOverview from '@/pages/home/transformation-analysis/children/overview'; // 转化概况

import Login from '@/pages/login';
import Users from '@/pages/users';
import Customer from '@/pages/customer';
import DataExchange from '@/pages/data-exchange';

import FinancialManagement from '@/pages/financial-management';
import Payment from '@/pages/financial-management/payment';
import FinancialReturn from '@/pages/financial-management/financial-return';
import FinancialReturnImport from '@/pages/financial-management/financial-return-import';
import FinancialReceipts from '@/pages/financial-management/financial-receipts';

import FinancialEIIM from '@/pages/financial-management/financial-eiim';
import FinancialDeposit from '@/pages/financial-management/financial-deposit';

import MarkResearch from '@/pages/market-research';
import Tasks from '@/pages/tasks';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to={'/general-situation'} />} />
        <Route path='/' element={<AuthComponent><LayoutComponent /></AuthComponent>}>

          <Route index path="/general-situation" element={<Home />}></Route>

          <Route path="data-analysis" element={<DataAnalysis />}>
            <Route path="real-time" element={<RealTime />}></Route>
            <Route path="trend" element={<Trend />}></Route>
          </Route>

          <Route path="origin" element={<SourceAnalysis />}>
            <Route path="all" element={<AllSource />}></Route>
            <Route path="engines" element={<EnginesSource />}></Route>
            <Route path="search-term" element={<SearchTerm />}></Route>
            <Route path="external-links" element={<ExternalLinks />}></Route>
          </Route>

          <Route path='access' element={<AccessAnalysis />}>
            <Route path="pageview" element={<InterviewedPage />}></Route>
            <Route path="entry-page" element={<EntryPage />}></Route>
            <Route path="visited-domain" element={<VisitedDomain />}></Route>
            <Route path="page-click-image" element={<PageClickImage />}></Route>
            <Route path="events" element={<Events />}></Route>
          </Route>

          <Route path='transformation-analysis' element={<TransformationAnalysis />}>
            <Route path='overview' element={<TransformationOverview />}></Route>
          </Route>

          <Route path='users' element={<Users />}></Route>
          <Route path='customer' element={<Customer />}></Route>
          <Route path='data-exchange' element={<DataExchange />}></Route>

          <Route path='financial-management' element={<FinancialManagement />}></Route>
          <Route path='financial-payment' element={<Payment />}></Route>
          <Route path='financial-return' element={<FinancialReturn />}></Route>
          <Route path='financial-return-import' element={<FinancialReturnImport />}></Route>
          <Route path='financial-receipts' element={<FinancialReceipts />}></Route>
          
          <Route path='financial-eiim' element={<FinancialEIIM />}></Route>
          <Route path='financial-deposit' element={<FinancialDeposit />}></Route>
          
          <Route path='market-research' element={<MarkResearch />}></Route>
          <Route path='tasks' element={<Tasks />}></Route>

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default observer(App);
