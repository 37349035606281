import { makeAutoObservable, runInAction } from "mobx";

class StateStore {

  isCollapsed = false;


  constructor() {
    makeAutoObservable(this);
  }

  toggleCollapsed(value) {
    runInAction(() => {
      this.isCollapsed = value;
    });
  }

}

export default StateStore;