import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Menu } from "antd";
import {
  PieChartFilled,
  ContactsFilled,
  DropboxCircleFilled,
  MoneyCollectFilled,
  FundFilled,
  ClockCircleFilled,
} from '@ant-design/icons';

import './menu.scss';

const items = [
  {
    key: '/',
    icon: <PieChartFilled />,
    label: '数据分析',
    children: [
      {
        key: '/general-situation',
        label: <Link to={'/general-situation'}>网站概况</Link>
      },
      {
        key: '/data-analysis',
        label: '流量分析',
        children: [
          {
            key: '/data-analysis/real-time',
            label: <Link to={'/data-analysis/real-time'}>实时访客</Link>
          },
          {
            key: '/data-analysis/trend',
            label: <Link to={'/data-analysis/trend'}>趋势分析</Link>
          }
        ]
      },
      {
        key: '/origin',
        label: '来源分析',
        children: [
          {
            key: '/origin/all',
            label: <Link to={'/origin/all'}>全部来源</Link>
          },
          {
            key: '/origin/engines',
            label: <Link to={'/origin/engines'}>搜索引擎</Link>
          },
          {
            key: '/origin/search-term',
            label: <Link to={'/origin/search-term'}>搜索词</Link>
          },
          {
            key: '/origin/external-links',
            label: <Link to={'/origin/external-links'}>外部链接</Link>
          }
        ]
      },
      {
        key: '/access',
        label: '访问分析',
        children: [
          {
            key: '/access/pageview',
            label: <Link to={'/access/pageview'}>受访页面</Link>
          },
          {
            key: '/access/entry-page',
            label: <Link to={'/access/entry-page'}>入口页面</Link>
          },
          {
            key: '/access/visited-domain',
            label: <Link to={'/access/visited-domain'}>受访域名</Link>
          },
          {
            key: '/access/page-click-image',
            label: <Link to={'/access/page-click-image'}>页面点击图</Link>
          },
          {
            key: '/access/events',
            label: <Link to={'/access/events'}>事件分析</Link>
          }
        ]
      },
      {
        key: '/transformation-analysis',
        label: '转化分析',
        children: [
          {
            key: '/transformation-analysis/overview',
            label: <Link to={'/transformation-analysis/overview'}>转化概况</Link>
          }
        ]
      },
    ]
  },
  {
    key: '/users',
    icon: <ContactsFilled />,
    label: <Link to={'/users'}>用户管理</Link>,
  },
  {
    key: '/data-exchange',
    icon: <DropboxCircleFilled />,
    label: <Link to={'/data-exchange'}>数据交互</Link>,
  },
  {
    key: '/financial-management',
    icon: <MoneyCollectFilled />,
    label: '财务管理',
    children: [
      {
        key: '/financial-management',
        label: <Link to={'/financial-management'}>数据统计</Link>
      },
      {
        key: '/financial-payment',
        label: <Link to={'/financial-payment'}>台账数据</Link>
      },
      {
        key: '/financial-return',
        label: <Link to={'/financial-return'}>回款数据</Link>
      },
      {
        key: '/financial-deposit',
        label: <Link to={'/financial-deposit'}>押金数据</Link>
      },
      {
        key: '/financial-return-import',
        label: <Link to={'/financial-return-import'}>回款数据导入</Link>
      },
      {
        key: '/financial-eiim',
        label: <Link to={'/financial-eiim'}>开票信息管理</Link>
      },
      {
        key: '/financial-receipts',
        label: <Link to={'/financial-receipts'}>预估收款</Link>
      }
    ]
  },
  {
    key: '/market-research',
    icon: <FundFilled />,
    label: <Link to={'/market-research'}>市场调研</Link>,
  },
  {
    key: '/tasks',
    icon: <ClockCircleFilled />,
    label: <Link to={'/tasks'}>任务管理</Link>,
  }
]

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};
const levelKeys = getLevelKeys(items);


function MenuComponent() {

  const location = useLocation();
  const { pathname } = location;
  const [stateOpenKeys, setStateOpenKeys] = useState(['/']);
  const [selectedKeys, setSelectedKeys] = useState([pathname]);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  useEffect(() => {
    setSelectedKeys([pathname]);
  }
  , [location.pathname]);


  return (
    <>
      <Menu
        className="menu-wrap"
        mode="inline"
        defaultSelectedKeys={selectedKeys}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        items={items}
      ></Menu>
    </>
  )
};

export default observer(MenuComponent);