import { useState } from 'react';
import HomeQueryForm from '@/components/home-query-form';
import HomeComponent from '@/components/home-component';
import { Card, Tabs } from 'antd';
function ExternalLinks() { // 外部链接组件
  const [tabs, setTabs] = useState('type');
  return (
    <div>
      <Card>
        <HomeQueryForm />

        <Tabs
          style={{ marginTop: '20px' }}
          type='card'
          onChange={(key) => setTabs(key)}
          items={[
            {
              label: '域名',
              key: 'term',
            },
            {
              label: 'URL',
              key: 'engine',
            }
          ]}
        ></Tabs>

        {
          tabs === 'term' ? <HomeComponent /> : <HomeComponent />
        }
      </Card>
    </div>
  );
};

export default ExternalLinks;