import { useEffect, useState } from "react";
import { Card, Input, Button, Space, Table } from "antd";
function FinancialDeposit() {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = generateData(1088);
    setTableData(data);
  },[])
  // 生成数据的函数
  const generateData = (count) => {
    // 根据表头字段生成数据，要求每条数据都不一样。
    // 要求：1、合同号从HT20210701001开始，每次递增1。2、公司（付款主体）从公司名称1开始，每次递增1。3、缴费性质有物业费、空调费、租金。4、入账时间从2021-07-01开始，每次递增1天。5、备注从备注1开始，每次递增1。6、账面（科目）有物业费、空调费、租金。7、金额从1000开始，每次递增1000。
    const data = [];
    for (let i = 0; i < count; i++) {
      data.push({
        key: i + 1,
        contractNo: `HT2021070100${i + 1}`,
        company: `公司名称${i + 1}`,
        paymentNature: ['物业费', '空调费', '租金'][i % 3],
        entryTime: '2021-07-01',
        remark: `备注${i + 1}`,
        bookBalance: ['物业费', '空调费', '租金'][i % 3],
        amount: 1000 * (i + 1)
      });
    }
    return data;
  };

  return (
    <>
      <Card
        title={<Button type="primary">押金信息录入</Button>}
        extra={
          <>
            <Space>
              <Input placeholder="请输入搜索内容" />
              <Button type="primary">导出</Button>
            </Space>
          </>
        }
      >
        <Table
          columns={[
            {
              title: '合同号',
              dataIndex: 'contractNo',
              key: 'contractNo',
            },
            {
              title: '公司（付款主体）',
              dataIndex: 'company',
              key: 'company',
            },
            {
              title: '缴费性质',
              dataIndex: 'paymentNature',
              key: 'paymentNature',
            },
            {
              title: '入账时间',
              dataIndex: 'entryTime',
              key: 'entryTime',
            },
            {
              title: '备注',
              dataIndex: 'remark',
              key: 'remark',
            },
            {
              title: '账面（科目）',
              dataIndex: 'bookBalance',
              key: 'bookBalance',
            },
            {
              title: '金额',
              dataIndex: 'amount',
              key: 'amount',
            },
            {
              title: '操作',
              dataIndex: 'operation',
              key: 'operation',
              render: (text, record) => (
                <>
                  <Button type="link" danger>删除</Button>
                </>
              )
            }
          ]}
          dataSource={tableData}
          pagination={{
            pageSize: 50
          }}
        ></Table>
      </Card>
    </>
  );
};

export default FinancialDeposit;