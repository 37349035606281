import { useState, useEffect } from 'react';
import { Input, Table, Card, Space, Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

function Payment() {

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = generateData(2305);
    setTableData(data);
  },[])

  // 生成数据的函数
  const generateData = (count) => {
    // 根据表头字段生成数据，要求每条数据都不一样。
    // 要求：1、时间从2021年开始，月份和日期随机。2、房间号从1至2层，号码随机。3、面积从30开始，最高130，随机。4、租金从50开始，最高64，随机数乘以随机的面积数。5、物业费固定5.6。6、空调费固定15。7、执行情况随机。8、预退租时间从2022年开始，月份和日期随机。9、实际结束时间从2022年开始，月份和日期随机。10、合同押金从5000开始，最高20000，随机。11、在途押金从1000开始，最高50000，随机。12、差异为合同押金减去在途押金。13、差异说明随机。14、差异预警随机。15、年度回款从4000开始，最高50000，随机。16、付款截止时间从2022年开始，月份和日期随机。17、逾期时间从2022年开始，月份和日期随机。18、逾期金额从0开始，最高50000，随机。19、状态随机。20、年度回款从1000开始，最高2000，随机。21、付款截止时间从2021年开始，月份和日期随机。22、逾期时间从2021年开始，月份和日期随机。23、逾期金额从100开始，最高500，随机。24、状态随机。25、年度回款从0开始，最高20000，随机。26、付款截止时间从2022年开始，月份和日期随机。27、状态随机。
    const data = [];
    for (let i = 0; i < count; i++) {
      data.push({
        key: i,
        contractNo: `HT2021${i + 1}`,
        contractTime: `2021-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}至2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        roomNo: `A${Math.floor(Math.random() * 6 + 1)}`,
        area: Math.floor(Math.random() * 100 + 30),
        rent: Math.floor(Math.random() * 14 + 50),
        propertyFee: 5.6,
        airConditioningFee: 15,
        execution: ['执行中', '已结束', '已撤销'][Math.floor(Math.random() * 3)],
        preLeaseTime: `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        actualEndTime: `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        'deposit-contractDeposit': Math.floor(Math.random() * 15000 + 5000),
        'deposit-onTheWayDeposit': Math.floor(Math.random() * 40000 + 1000),
        'deposit-difference': 0,
        'deposit-differenceDescription': ['正常', '异常', '已撤销'][Math.floor(Math.random() * 3)],
        'deposit-differenceWarning': ['警告', '正常', '异常', '已撤销'][Math.floor(Math.random() * 4)],
        'rent-annualPayment': Math.floor(Math.random() * 46000 + 4000),
        'rent-paymentDeadline': `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        'rent-overdueTime': `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        'rent-overdueAmount': Math.floor(Math.random() * 50000),
        'rent-status': ['正常', '严重欠费', '已欠费'][Math.floor(Math.random() * 3)],
        'propertyFee-annualPayment': Math.floor(Math.random() * 1760 + 240),
        'propertyFee-paymentDeadline': `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        'propertyFee-overdueTime': `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        'propertyFee-overdueAmount': Math.floor(Math.random() * 5000),
        'propertyFee-status': ['正常', '严重欠费', '已欠费'][Math.floor(Math.random() * 3)],
        'airConditioningFee-annualPayment': Math.floor(Math.random() * 880 + 120),
        'airConditioningFee-paymentDeadline': `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        'airConditioningFee-status': ['正常', '严重欠费', '已欠费'][Math.floor(Math.random() * 3)],
      });
    }
    return data;
  }
  return (
    <>
      <Card>
        <div>
          <Input placeholder='请输入合同号/公司主体/单位名称查询'></Input>
        </div>
        <Table
          columns={[
            { title: '合同号/公司名称', dataIndex: 'contractNo', key: 'contractNo', fixed: 'left', width: 200 },
            { title: '合同起止时间', dataIndex: 'contractTime', key: 'contractTime' },
            { title: '房间号', dataIndex: 'roomNo', key: 'roomNo' },
            { title: '面积（㎡）', dataIndex: 'area', key: 'area' },
            { title: '租金（元/㎡/月）', dataIndex: 'rent', key: 'rent' },
            { title: '物业费（元/㎡/月）', dataIndex: 'propertyFee', key: 'propertyFee' },
            { title: '空调费（元/㎡/月）', dataIndex: 'airConditioningFee', key: 'airConditioningFee' },
            { title: '执行情况', dataIndex: 'execution', key: 'execution' },
            { title: '预退租时间', dataIndex: 'preLeaseTime', key: 'preLeaseTime' },
            { title: '实际结束时间', dataIndex: 'actualEndTime', key: 'actualEndTime' },
            {
              title: '押金',
              children: [
                { title: '合同押金', dataIndex: 'deposit-contractDeposit', key: 'deposit-contractDeposit' },
                { title: '在途押金', dataIndex: 'deposit-onTheWayDeposit', key: 'deposit-onTheWayDeposit' },
                { title: '差异', dataIndex: 'deposit-difference', key: 'deposit-difference' },
                { title: '差异说明', dataIndex: 'deposit-differenceDescription', key: 'deposit-differenceDescription' },
                { title: '差异预警', dataIndex: 'deposit-differenceWarning', key: 'deposit-differenceWarning' }, // 警告、正常、异常、已撤销
              ]
            },
            {
              title: '房租',
              children: [
                { title: '年度回款', dataIndex: 'rent-annualPayment', key: 'rent-annualPayment' },
                { title: '付款截止时间', dataIndex: 'rent-paymentDeadline', key: 'rent-paymentDeadline' },
                { title: '逾期时间', dataIndex: 'rent-overdueTime', key: 'rent-overdueTime' },
                { title: '逾期金额', dataIndex: 'rent-overdueAmount', key: 'rent-overdueAmount' },
                { title: '状态', dataIndex: 'rent-status', key: 'rent-status' }, // 正常、严重欠费、已欠费
              ]
            },
            {
              title: '物业费',
              children: [
                { title: '年度回款', dataIndex: 'propertyFee-annualPayment', key: 'propertyFee-annualPayment' },
                { title: '付款截止时间', dataIndex: 'propertyFee-paymentDeadline', key: 'propertyFee-paymentDeadline' },
                { title: '逾期时间', dataIndex: 'propertyFee-overdueTime', key: 'propertyFee-overdueTime' },
                { title: '逾期金额', dataIndex: 'propertyFee-overdueAmount', key: 'propertyFee-overdueAmount' },
                { title: '状态', dataIndex: 'propertyFee-status', key: 'propertyFee-status' }, // 正常、严重欠费、已欠费
              ]
            },
            {
              title: '空调费',
              children: [
                { title: '年度回款', dataIndex: 'airConditioningFee-annualPayment', key: 'airConditioningFee-annualPayment' },
                { title: '付款截止时间', dataIndex: 'airConditioningFee-paymentDeadline', key: 'airConditioningFee-paymentDeadline' },
                { title: '状态', dataIndex: 'airConditioningFee-status', key: 'airConditioningFee-status' }, // 正常、严重欠费、已欠费
              ]
            },
            {
              title: '操作',
              dataIndex: 'operation',
              key: 'operation',
              align: 'end',
              fixed: 'right',
              width: 120,
              render: () => (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: '回款数据',
                        onClick: () => { }
                      },
                      {
                        key: '2',
                        label: '押金数据',
                        onClick: () => { }
                      },
                      {
                        key: '3',
                        label: '撤销预警',
                        onClick: () => { }
                      },
                      {
                        key: '4',
                        label: '确认退租',
                      }
                    ]
                  }}
                >
                  <Button icon={<DownOutlined />} iconPosition='end' type='link'>操作</Button>
                </Dropdown>
              )
            }
          ]}
          dataSource={tableData}
          scroll={{ x: 3000 }}
          bordered
          size='small'
          pagination={{ pageSize: 50 }}
        ></Table>
      </Card>
    </>
  )
};

export default Payment;