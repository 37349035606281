import { Table } from "antd";
function TableView(props) {

  const { colums, dataSources } = props;

  return (
    <>
      <Table
        columns={colums}
        dataSource={dataSources}
        size='small'
        rowKey={record => record.id}
        pagination={false}
      ></Table>
    </>
  )

};

export default TableView;