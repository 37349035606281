import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import { Card, Form, Radio, Select, DatePicker, Space, Row, Col, Table } from "antd";
const { Meta } = Card;
function Trend() {
  const chartRef = useRef(null);
  // 获取近30分钟的时间，把每分钟保存到数组中
  const time = new Date();
  const timeArr = [];
  for (let i = 0; i < 24; i++) {
    timeArr.push(i);
  }
  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [])
  const initChart = (chartInstance) => {
    chartInstance.setOption({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['浏览量(PV)'],
        bottom: 0
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: timeArr
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '浏览量(PV)',
          data: [75653,47604,30387,21376,17615,20143,25199,31631,49044,65882,68486,64708,65112,76240,83241,81836,28772,0,0,0,0,0,0,0],
          type: 'line',
          stack: 'Total',
          areaStyle: {
            color: '#4fa8f9'
          },
          emphasis: {
            focus: 'series'
          },
        },
      ]
    });
  }
  return (
    <div>
      <Card title="趋势分析">
        <Form layout="inline">
          <Form.Item label="时间">
            <Space>
              <Form.Item>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={'today'}>今天</Radio.Button>
                  <Radio.Button value={'yesterday'}>昨天</Radio.Button>
                  <Radio.Button value={'week'}>最近7天</Radio.Button>
                  <Radio.Button value={'month'}>最近30天</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <DatePicker.RangePicker />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label="来源">
            <Select
              placeholder="选择来源"
              options={[
                {
                  label: '全部',
                  value: 'all'
                },
                {
                  label: '直接访问',
                  value: 'direct'
                },
                {
                  label: '搜索引擎',
                  value: 'search'
                },
                {
                  label: '外部链接',
                  value: 'external'

                }
              ]}
            >
            </Select>
          </Form.Item>
          <Form.Item label="设备">
            <Select
              placeholder="选择设备"
              options={[
                {
                  label: '全部',
                  value: 'all'
                },
                {
                  label: '计算机',
                  value: 'pc'
                },
                {
                  label: '移动设备',
                  value: 'mobile'
                }
              ]}
            >
            </Select>
          </Form.Item>
          <Form.Item label="地域">
            <Select
              allowClear
              showSearch
              placeholder="选择地域"
              options={[
                {
                  label: '全部',
                  value: 'all'
                },
                {
                  label: '北京',
                  value: 'beijing'
                },
                {
                  label: '上海',
                  value: 'shanghai'
                }
              ]}
            >
            </Select>
          </Form.Item>
          <Form.Item label="访客">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={'pv'}>全部</Radio.Button>
              <Radio.Button value={'uv'}>新访客</Radio.Button>
              <Radio.Button value={'vv'}>老访客</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
        <Row gutter={20} style={{ marginTop: 30 }}>
          <Col offset={2} span={4}>
            <Card bordered={false}>
              <Meta title="浏览量（PV）" description="850,939" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="访客数（UV）" description="413,058" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="IP数" description="410,651" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="跳出率" description="73.9%" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="平均访问时长" description="00:02:50" />
            </Card>
          </Col>
        </Row>
        <div>
          <div ref={chartRef} className='chart-base' style={{ height: 412 }}></div>
        </div>
        <Table
          size='small'
          bordered
          pagination={false}
          style={{ marginTop: 20 }}
          columns={[
            {
              title: '日期',
              dataIndex: 'date',
              align: 'center',
              key: 'date'
            },
            {
              title: '网站基础指标',
              children: [
                {
                  title: '浏览量(PV)',
                  dataIndex: 'pv',
                  align: 'center',
                  key: 'pv'
                },
                {
                  title: '访客数(UV)',
                  dataIndex: 'uv',
                  align: 'center',
                  key: 'uv'
                },
                {
                  title: 'IP数',
                  dataIndex: 'ip',
                  align: 'center',
                  key: 'ip'
                },
                
              ]
            },
            {
              title: '流量质量指标',
              children: [
                {
                  title: '跳出率',
                  dataIndex: 'bounceRate',
                  key: 'bounceRate',
                  align: 'center'
                },
                {
                  title: '平均访问时长',
                  align: 'center',
                  dataIndex: 'avgVisitTime',
                  key: 'avgVisitTime'
                }
              ]
            }
          ]}
          dataSource={[
            {
              key: '1',
              date: '2024-08-01',
              pv: 75653,
              uv: 47604,
              ip: 30387,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '2',
              date: '2024-08-02',
              pv: 47604,
              uv: 30387,
              ip: 21376,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '3',
              date: '2024-08-03',
              pv: 30387,
              uv: 21376,
              ip: 17615,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '4',
              date: '2024-08-04',
              pv: 21376,
              uv: 17615,
              ip: 20143,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '5',
              date: '2024-08-05',
              pv: 17615,
              uv: 20143,
              ip: 25199,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '6',
              date: '2024-08-06',
              pv: 20143,
              uv: 25199,
              ip: 31631,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '7',
              date: '2024-08-07',
              pv: 25199,
              uv: 31631,
              ip: 49044,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '8',
              date: '2024-08-08',
              pv: 31631,
              uv: 49044,
              ip: 65882,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '9',
              date: '2024-08-09',
              pv: 49044,
              uv: 65882,
              ip: 68486,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '10',
              date: '2024-08-10',
              pv: 65882,
              uv: 68486,
              ip: 64708,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '11',
              date: '2024-08-11',
              pv: 68486,
              uv: 64708,
              ip: 65112,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '12',
              date: '2024-08-12',
              pv: 64708,
              uv: 65112,
              ip: 76240,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '13',
              date: '2024-08-13',
              pv: 65112,
              uv: 76240,
              ip: 83241,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '14',
              date: '2024-08-14',
              pv: 76240,
              uv: 83241,
              ip: 81836,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '15',
              date: '2024-08-15',
              pv: 83241,
              uv: 81836,
              ip: 28772,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '16',
              date: '2024-08-16',
              pv: 81836,
              uv: 28772,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '17',
              date: '2024-08-17',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '18',
              date: '2024-08-18',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '19',
              date: '2024-08-19',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '20',
              date: '2024-08-20',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '21',
              date: '2024-08-21',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '22',
              date: '2024-08-22',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '23',
              date: '2024-08-23',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            },
            {
              key: '24',
              date: '2024-08-24',
              pv: 0,
              uv: 0,
              ip: 0,
              bounceRate: '73.9%',
              avgVisitTime: '00:02:50'
            }
          ]}
        ></Table>
      </Card>
    </div>
  );
};

export default Trend;