import { Card, Row, Col, Table } from 'antd';
import HomeQueryForm from '@/components/home-query-form';

const { Meta } = Card;
function VisitedDomain() {
  return (
    <>
      <Card title="受访页面">
        <HomeQueryForm />

        <Row gutter={20} style={{ marginTop: 30 }}>
          <Col offset={2} span={4}>
            <Card bordered={false}>
              <Meta title="浏览量（PV）" description="1,329,834" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="访客数（UV）" description="693,843" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="IP数" description="686,652" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="跳出率" description="74.98%" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="平均停留时长" description="00:01:49" />
            </Card>
          </Col>
        </Row>

        <Table
          size='small'
          bordered
          columns={[
            {
              title: '',
              dataIndex: 'index',
              key: 'index',
              render: (text, record, index) => index + 1
            },
            {
              title: '域名',
              dataIndex: 'url',
              key: 'url',
              width: 930
            },
            {
              title: '网站基础指标',
              children: [
                {
                  title: '浏览量（PV）',
                  dataIndex: 'pv',
                  key: 'pv'
                },
                {
                  title: '访客数（UV）',
                  dataIndex: 'uv',
                  key: 'uv'
                },
                {
                  title: 'IP数',
                  dataIndex: 'ip',
                  key: 'ip'
                }
              ]
            },
            {
              title: '流量质量指标',
              children: [
                {
                  title: '跳出率',
                  dataIndex: 'exit',
                  key: 'exit'
                },
                {
                  title: '平均停留时长',
                  dataIndex: 'stay',
                  key: 'stay'
                }
              ]
            }
          ]}
          dataSource={[
            {
              id: 1,
              url: 'https://ty-platform.cmvstv.com',
              pv: 521318,
              uv: 340025,
              ip: 337945,
              exit: '79.32%',
              stay: '00:01:49'
            },
            {
              id: 2,
              url: 'https://ty-platform.cmvstv.com/web/homepage',
              pv: 421217,
              uv: 240024,
              ip: 237844,
              exit: '75.32%',
              stay: '00:02:49'
            },
            {
              id: 3,
              url: 'https://ty-platform.cmvstv.com/web/contract',
              pv: 321116,
              uv: 140023,
              ip: 137743,
              exit: '71.32%',
              stay: '00:03:49'
            },
            {
              id: 4,
              url: 'https://ty-platform.cmvstv.com/list',
              pv: 221015,
              uv: 40022,
              ip: 37742,
              exit: '67.32%',
              stay: '00:04:49'
            },
            {
              id: 5,
              url: 'https://ty-platform.cmvstv.com/web/contract/contractDetail?contractId=1&siteId=12741553&siteTime=1617235200&userId=1#/contract/contractDetail',
              pv: 120914,
              uv: 30021,
              ip: 27741,
              exit: '63.32%',
              stay: '00:05:49'
            },
            {
              id: 6,
              url: 'https://ty-platform.cmvstv.com/custom/media',
              pv: 20913,
              uv: 20020,
              ip: 17740,
              exit: '59.32%',
              stay: '00:06:49'
            },
            {
              id: 7,
              url: 'https://ty-platform.cmvstv.com/optes',
              pv: 30812,
              uv: 10019,
              ip: 7740,
              exit: '55.32%',
              stay: '00:07:49'
            },
            {
              id: 8,
              url: 'https://ty-platform.cmvstv.com/web/homepage',
              pv: 40711,
              uv: 5018,
              ip: 6739,
              exit: '51.32%',
              stay: '00:08:49'
            },
            {
              id: 9,
              url: 'https://ty-platform.cmvstv.com/analytics/conversion/overview',
              pv: 50610,
              uv: 4017,
              ip: 5738,
              exit: '47.32%',
              stay: '00:09:49'
            },
            {
              id: 10,
              url: 'https://ty-platform.cmvstv.com/pro/hour',
              pv: 60509,
              uv: 3016,
              ip: 4737,
              exit: '43.32%',
              stay: '00:10:49'
            },
            {
              id: 11,
              url: 'https://ty-platform.cmvstv.com/analytics/funnel',
              pv: 70408,
              uv: 2015,
              ip: 3736,
              exit: '39.32%',
              stay: '00:11:49'
            },
            {
              id: 12,
              url: 'https://ty-platform.cmvstv.com/sc-web/home/account/add',
              pv: 80307,
              uv: 1014,
              ip: 2735,
              exit: '35.32%',
              stay: '00:12:49'
            },
            {
              id: 13,
              url: 'https://ty-platform.cmvstv.com/visit/topdomain',
              pv: 90206,
              uv: 13,
              ip: 1734,
              exit: '31.32%',
              stay: '00:13:49'
            },
            {
              id: 14,
              url: 'https://ty-platform.cmvstv.com/web/source/all',
              pv: 100205,
              uv: 12,
              ip: 733,
              exit: '27.32%',
              stay: '00:14:49'
            },
            {
              id: 15,
              url: 'https://ty-platform.cmvstv.com/web/trend/history',
              pv: 110204,
              uv: 11,
              ip: 732,
              exit: '23.32%',
              stay: '00:15:49'
            },
            {
              id: 16,
              url: 'https://ty-platform.cmvstv.com/custom/subdir',
              pv: 120203,
              uv: 10,
              ip: 731,
              exit: '19.32%',
              stay: '00:16:49'
            },
            {
              id: 17,
              url: 'https://ty-platform.cmvstv.com/visit/landingpage',
              pv: 130202,
              uv: 9,
              ip: 730,
              exit: '15.32%',
              stay: '00:17:49'
            },
            {
              id: 18,
              url: 'https://ty-platform.cmvstv.com/custom/media',
              pv: 140201,
              uv: 8,
              ip: 729,
              exit: '11.32%',
              stay: '00:18:49'
            },
            {
              id: 19,
              url: 'https://ty-platform.cmvstv.com/home/subdir/create',
              pv: 150200,
              uv: 7,
              ip: 728,
              exit: '7.32%',
              stay: '00:19:49'
            },
            {
              id: 20,
              url: 'https://ty-platform.cmvstv.com/sc-web/home/anti',
              pv: 160199,
              uv: 6,
              ip: 727,
              exit: '3.32%',
              stay: '00:20:49'
            },
          ]}
          pagination={{
            defaultPageSize: 20,
          }}
        ></Table>
      </Card>
    </>
  )
};

export default VisitedDomain;