import { useState } from 'react';
import HomeQueryForm from '@/components/home-query-form';
import HomeComponent from '@/components/home-component';
import { Card, Tabs } from 'antd';
function SearchTerm() {
  const [tabs, setTabs] = useState('type');
  return (
    <div>
      <Card>
        <HomeQueryForm />

        <Tabs
          style={{ marginTop: '20px' }}
          type='card'
          onChange={(key) => setTabs(key)}
          items={[
            {
              label: '按指标分类',
              key: 'term',
            },
            {
              label: '按搜索引擎',
              key: 'engine',
            }
          ]}
        ></Tabs>

        {
          tabs === 'term' ? <HomeComponent /> : <HomeComponent />
        }
      </Card>
    </div>
  );
};

export default SearchTerm;