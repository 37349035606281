import {Card, Table} from 'antd';
function Tasks() {
  return (
    <div>
      <Card title="任务列表">
        <Table
          bordered
          size='small'
          dataSource={[
            {
              id: 1,
              name: '导入新闻',
              content: '文件大小：21KB，文件名：news.xlsx',
              time: '2024-08-01 12:24'
            },
            {
              id: 2,
              name: '导入用户',
              content: '文件大小：1M，文件名：users.xlsx',
              time: '2024-08-01 14:12'
            },
            {
              id: 4,
              name: '导入财务报表',
              content: '文件大小：1M，文件名：orders.xlsx',
              time: '2024-06-20 08:11'
            }
          ]}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id'
            },
            {
              title: '任务名称',
              dataIndex: 'name',
              key: 'name'
            },
            {
              title: '描述',
              dataIndex: 'content',
              key: 'content'
            },
            {
              title: '时间',
              dataIndex: 'time',
              key: 'time'
            }
          ]}
          pagination={false}
        ></Table>
      </Card>
    </div>
  );
};

export default Tasks;