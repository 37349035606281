import { useState, useEffect } from 'react';
import { Row, Col, Card, Radio, Table } from 'antd';
import { FallOutlined } from '@ant-design/icons';
import TableView from './components/tableView';
import TrendChart from './components/trend-chart'; // 趋势图
import VisitorAge from './components/visitor-age'; // 访客年龄分布
import './home.scss';

const dobuleArrowDown = require('@/assets/icons/double-arrow-down.png');
const dobuleArrowUp = require('@/assets/icons/double-arrow-up.png');
const guestPng = require('@/assets/icons/guest.png');

function Home() {
  const [showYesterday, setShowYesterday] = useState(false);

  const toggleClick = () => {
    setShowYesterday(!showYesterday);
  }

  const top10KwColumns = [
    {
      title: <div className='t-header'>搜索词</div>,
      dataIndex: 'kw',
      key: 'kw',
    },
    {
      title: <div className='t-header'>浏览量(PV)</div>,
      dataIndex: 'pv',
      key: 'pv',
      align: 'right',
      width: 188
    },
    {
      title: <div className='t-header'>占比</div>,
      dataIndex: 'rate',
      key: 'rate',
      width: 132
    }
  ]
  const top10KwData = [
    {
      id: 1,
      kw: '文化产业',
      pv: '4,231',
      rate: <div className='rate-box' style={{ width: '2.97%' }}>2.97%</div>
    },
    {
      id: 2,
      kw: '视频+直播+文化',
      pv: '4,029',
      rate: <div className='rate-box' style={{ width: '2.28%' }}>2.28%</div>
    },
    {
      id: 3,
      kw: '主播',
      pv: '3,938',
      rate: <div className='rate-box' style={{ width: '2.76%' }}>2.76%</div>
    },
    {
      id: 4,
      kw: '园区文化产业',
      pv: '2,534',
      rate: <div className='rate-box' style={{ width: '1.78%' }}>1.78%</div>
    },
    {
      id: 5,
      kw: '马栏山视频产业',
      pv: '2,199',
      rate: <div className='rate-box' style={{ width: '1.54%' }}>1.54%</div>
    },
    {
      id: 6,
      kw: '运营平台',
      pv: '2,009',
      rate: <div className='rate-box' style={{ width: '1.41%' }}>1.41%</div>
    },
    {
      id: 7,
      kw: '天衍',
      pv: '1,823',
      rate: <div className='rate-box' style={{ width: '1.28%' }}>1.28%</div>
    },
    {
      id: 8,
      kw: '直播基地',
      pv: '1,608',
      rate: <div className='rate-box' style={{ width: '1.13%' }}>1.13%</div>
    },
    {
      id: 9,
      kw: '产业服务',
      pv: '1,530',
      rate: <div className='rate-box' style={{ width: '1.07%' }}>1.07%</div>
    },
    {
      id: 10,
      kw: '产业大数据',
      pv: '1,480',
      rate: <div className='rate-box' style={{ width: '1.04%' }}>1.04%</div>
    }
  ]
  const top10VisitPageColumns = [
    {
      title: <div className='t-header'>受访页面</div>,
      dataIndex: 'pathname',
      key: 'pathname',
    },
    {
      title: <div className='t-header'>浏览量(PV)</div>,
      dataIndex: 'pv',
      key: 'pv',
    },
    {
      title: <div className='t-header'>占比</div>,
      dataIndex: 'rate',
      key: 'rate',
      width: 132,
      render: (text) => <div className='rate-box' style={{ width: text }}>{text}</div>
    }
  ]
  const top10VisitPageData = [
    {
      id: 1,
      pathname: 'https://ty-platform.cmvstv.com/index.html',
      pv: '19,078',
      rate: '4.99%'
    },
    {
      id: 2,
      pathname: 'https://ty-platform.cmvstv.com/about.html',
      pv: '12,466',
      rate: '3.26%'
    },
    {
      id: 3,
      pathname: 'https://ty-platform.cmvstv.com/product.html',
      pv: '12,209',
      rate: '3.19%'
    },
    {
      id: 4,
      pathname: 'https://ty-platform.cmvstv.com/service.html',
      pv: '11,975',
      rate: '3.13%'
    },
    {
      id: 5,
      pathname: 'https://ty-platform.cmvstv.com/contact.html',
      pv: '9,965',
      rate: '2.61%'
    },
    {
      id: 6,
      pathname: 'https://ty-platform.cmvstv.com/news.html',
      pv: '8,022',
      rate: '2.1%'
    },
    {
      id: 7,
      pathname: 'https://ty-platform.cmvstv.com/case.html',
      pv: '7,803',
      rate: '2.04%'
    },
    {
      id: 8,
      pathname: 'https://ty-platform.cmvstv.com/help.html',
      pv: '6,793',
      rate: '1.78%'
    },
    {
      id: 9,
      pathname: 'https://ty-platform.cmvstv.com/faq.html',
      pv: '5,575',
      rate: '1.46%'
    },
    {
      id: 10,
      pathname: 'https://ty-platform.cmvstv.com/sitemap.html',
      pv: '5,162',
      rate: '1.35%'
    }
  ]
  const top10SourceColumns = [
    {
      title: <div className='t-header'>来源网站</div>,
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: <div className='t-header'>浏览量(PV)</div>,
      dataIndex: 'pv',
      key: 'pv',
    },
    {
      title: <div className='t-header'>占比</div>,
      dataIndex: 'rate',
      key: 'rate',
      render: (text) => <div className='rate-box' style={{ width: text }}>{text}</div>
    }
  ]
  const top10SourceData = [
    {
      id: 1,
      source: '直接访问',
      pv: '320,011',
      rate: '46.64%'
    },
    {
      id: 2,
      source: '百度',
      pv: '194,286',
      rate: '28.32%'
    },
    {
      id: 3,
      source: '神马搜索',
      pv: '40,761',
      rate: '5.94%'
    },
    {
      id: 4,
      source: '360搜索',
      pv: '29,077',
      rate: '4.24%'
    },
    {
      id: 5,
      source: '搜狗',
      pv: '13,725',
      rate: '2%'
    },
    {
      id: 6,
      source: 'https://ty-platform.cmvstv.com/web/opt/speed',
      pv: '13,116',
      rate: '1.91%'
    },
    {
      id: 7,
      source: 'https://ty-platform.cmvstv.com/web/custom/subdir',
      pv: '12,638',
      rate: '1.84%'
    },
    {
      id: 8,
      source: 'https://ty-platform.cmvstv.com/sc-web/home/customevent',
      pv: '10,644',
      rate: '1.55%'
    },
    {
      id: 9,
      source: 'https://ty-platform.cmvstv.com/sc-web/home/anti',
      pv: '9,549',
      rate: '1.39%'
    },
    {
      id: 10,
      source: '其他',
      pv: '42,341',
      rate: '6.17%'
    }
  ]
  const top10EntryPageColumns = [
    {
      title: <div className='t-header'>入口页面</div>,
      dataIndex: 'entryUrl',
      key: 'entryUrl'
    },
    {
      title: <div className='t-header'>浏览量(PV)</div>,
      dataIndex: 'pv',
      key: 'pv'
    },
    {
      title: <div className='t-header'>占比</div>,
      dataIndex: 'rate',
      key: 'rate',
      render: (text) => <div className='rate-box' style={{ width: text }}>{text}</div>
    }
  ]
  const top10EvtryPageData = [
    {
      id: 1,
      entryUrl: 'https://ty-platform.cmvstv.com/analytics/conversion/overview',
      pv: '30,893',
      rate: '6.45%'
    },
    {
      id: 2,
      entryUrl: 'https://ty-platform.cmvstv.com/web/source/all',
      pv: '27,116',
      rate: '5.66%'
    },
    {
      id: 3,
      entryUrl: 'https://ty-platform.cmvstv.com/web/visit/attribute',
      pv: '25,502',
      rate: '5.32%'
    },
    {
      id: 4,
      entryUrl: 'https://ty-platform.cmvstv.com/sc-web/home/reportsend',
      pv: '11,558',
      rate: '2.41%'
    },
    {
      id: 5,
      entryUrl: 'https://ty-platform.cmvstv.com/web/pro/hour',
      pv: '11,109',
      rate: '2.32%'
    },
    {
      id: 6,
      entryUrl: 'https://ty-platform.cmvstv.com/web/trend/latest',
      pv: '10,889',
      rate: '2.27%'
    },
    {
      id: 7,
      entryUrl: 'https://ty-platform.cmvstv.com/web/optes',
      pv: '8,809',
      rate: '1.84%'
    },
    {
      id: 8,
      entryUrl: 'https://ty-platform.cmvstv.com/web/custom/subdir',
      pv: '7,943',
      rate: '1.66%'
    },
    {
      id: 9,
      entryUrl: 'https://ty-platform.cmvstv.com/sc-web/home/account',
      pv: '5,785',
      rate: '1.21%'
    },
    {
      id: 10,
      entryUrl: 'https://ty-platform.cmvstv.com/web/visit/topdomain',
      pv: '5,112',
      rate: '1.07%'
    }
  ]

  return (
    <>
      <Card title='今日流量'>
        <Row gutter={10}>
          <Col span={3}>
            <Row gutter={[0, 10]}>
              <Col span={24}>&nbsp;</Col>
              <Col span={24}>今日</Col>
              <Col span={24}>昨日</Col>
              <Col span={24}>预计今日</Col>
            </Row>
          </Col>
          <Col span={21}>
            <Row gutter={10}>
              <Col span={4}>
                <Row gutter={[0, 10]}>
                  <Col span={24}><span className=''>浏览量(PV)</span></Col>
                  <Col span={24}><span className='t1'>412,616</span></Col>
                  <Col span={24}><span>1,522,152</span></Col>
                  <Col span={24}><span>1,4922,236<FallOutlined className='fall-icon' /></span></Col>
                </Row>
              </Col>
              <Col span={4}>
                <Row gutter={[0, 10]}>
                  <Col span={24}><span className=''>访客数(UV)</span></Col>
                  <Col span={24}><span className='t1'>199,794</span></Col>
                  <Col span={24}><span>682,804</span></Col>
                  <Col span={24}><span>660,176<FallOutlined className='fall-icon' /></span></Col>
                </Row>
              </Col>
              <Col span={4}>
                <Row gutter={[0, 10]}>
                  <Col span={24}><span className=''>IP数</span></Col>
                  <Col span={24}><span className='t1'>195,541</span></Col>
                  <Col span={24}><span>671,564</span></Col>
                  <Col span={24}><span>647,567<FallOutlined className='fall-icon' /></span></Col>
                </Row>
              </Col>
              <Col span={4}>
                <Row gutter={[0, 10]}>
                  <Col span={24}><span className=''>跳出率</span></Col>
                  <Col span={24}><span className='t1'>72.25%</span></Col>
                  <Col span={24}><span>70.38%</span></Col>
                  <Col span={24}><span>--</span></Col>
                </Row>
              </Col>
              <Col span={4}>
                <Row gutter={[0, 10]}>
                  <Col span={24}><span className=''>平均访问时长</span></Col>
                  <Col span={24}><span className='t1'>00:02:35</span></Col>
                  <Col span={24}><span>00:03:13</span></Col>
                  <Col span={24}><span>--</span></Col>
                </Row>
              </Col>
              <Col span={4}>
                <Row gutter={[0, 10]}>
                  <Col span={24}><span className=''>转化次数</span></Col>
                  <Col span={24}><span className='t1'>974</span></Col>
                  <Col span={24}><span>7,536</span></Col>
                  <Col span={24}><span>4,121<FallOutlined className='fall-icon' /></span></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='yesterday-box'>
          <div className='empty-line'></div>
          {
            showYesterday && (
              <div className='yesterday-content'>
                <Row gutter={10}>
                  <Col span={3}>
                    <Row gutter={[0, 10]}>
                      <Col span={24}>昨日此时</Col>
                      <Col span={24}>每日平均</Col>
                      <Col span={24}>历史巅峰</Col>
                    </Row>
                  </Col>
                  <Col span={21}>
                    <Row gutter={10}>
                      <Col span={4}>
                        <Row gutter={[0, 10]}>
                          <Col span={24}><span>413,913</span></Col>
                          <Col span={24}><span>1,604,018</span></Col>
                          <Col span={24}><span>1,855,290</span></Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row gutter={[0, 10]}>
                          <Col span={24}><span>203,621</span></Col>
                          <Col span={24}><span>770,699</span></Col>
                          <Col span={24}><span>936,820</span></Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row gutter={[0, 10]}>
                          <Col span={24}><span>199,847</span></Col>
                          <Col span={24}><span>756,351</span></Col>
                          <Col span={24}><span>920,402</span></Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row gutter={[0, 10]}>
                          <Col span={24}><span>71.8%</span></Col>
                          <Col span={24}><span>73.79%</span></Col>
                          <Col span={24}><span>75.09%</span></Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row gutter={[0, 10]}>
                          <Col span={24}><span>00:03:08</span></Col>
                          <Col span={24}><span>00:02:47</span></Col>
                          <Col span={24}><span>00:03:14</span></Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row gutter={[0, 10]}>
                          <Col span={24}><span>1,605</span></Col>
                          <Col span={24}><span>5,994</span></Col>
                          <Col span={24}><span>7,853</span></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )
          }
          <div className='toggle-box' onClick={toggleClick}>
            {
              showYesterday ? <img src={dobuleArrowUp} alt='' className='toggle-icon' /> : <img src={dobuleArrowDown} alt='' className='toggle-icon' />
            }

          </div>
        </div>
      </Card>
      <div className='tabs-box'>
        <Radio.Group defaultValue="a" buttonStyle="solid">
          <Radio.Button value="a">今日</Radio.Button>
          <Radio.Button value="b">昨日</Radio.Button>
          <Radio.Button value="c">近7日</Radio.Button>
          <Radio.Button value="d">近30日</Radio.Button>
        </Radio.Group>
      </div>
      <Row gutter={[40, 20]}>
        <Col span={12}>
          <Card title="趋势图">
            <TrendChart />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top10搜索词">
            <TableView colums={top10KwColumns} dataSources={top10KwData} key="top10Kw" />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top10来源网站">
            <TableView colums={top10SourceColumns} dataSources={top10SourceData} key="top10Scource" />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top10入口页面">
            <TableView colums={top10EntryPageColumns} dataSources={top10EvtryPageData} key="top10Entry" />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top10受访页面">
            <TableView colums={top10VisitPageColumns} dataSources={top10VisitPageData} key="top10Visit" />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="新老访客">
            <Row gutter={[0, 40]}>
              <Col span={24}>
                <Row style={{margin: '20px 0'}}>
                  <Col span={8}>
                    <div style={{textAlign:'center'}}>
                      <img src={guestPng} alt='' />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='v-block'>
                      <div>新访客</div>
                      <div className='v-rate'>50.61%</div>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='v-block'>
                      <div>老访客</div>
                      <div className='v-rate'>49.39%</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8} className='v-col'>浏览量</Col>
                  <Col span={8} className='v-col'>287175</Col>
                  <Col span={8} className='v-col'>398939</Col>
                  <Col span={8} className='v-col'>访客数</Col>
                  <Col span={8} className='v-col'>162559</Col>
                  <Col span={8} className='v-col'>158619</Col>
                  <Col span={8} className='v-col'>跳出率</Col>
                  <Col span={8} className='v-col'>73.92%</Col>
                  <Col span={8} className='v-col'>69.13%</Col>
                  <Col span={8} className='v-col'>平均访问时长</Col>
                  <Col span={8} className='v-col'>00:01:50</Col>
                  <Col span={8} className='v-col'>00:04:01</Col>
                  <Col span={8} className='v-col'>平均访问页数</Col>
                  <Col span={8} className='v-col'>1.53</Col>
                  <Col span={8} className='v-col'>1.9</Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="访客属性-年龄分布">
            <VisitorAge />
          </Card>
        </Col>
      </Row>
    </>
  )
};

export default Home;