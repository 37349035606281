import { Card, Table } from "antd";
function PageClickImage() {
  return (
    <>
      <Card title="页面点击图">
        <Table
          columns={[
            {
              title: '',
              dataIndex: 'index',
              key: 'index',
              render: (text, record, index) => index + 1
            },
            {
              title: '点击图名称',
              dataIndex: 'name',
              key: 'name'
            },
            {
              title: '页面范围',
              dataIndex: 'range',
              key: 'range'
            },
            {
              title: '预览页面',
              dataIndex: 'preview',
              key: 'preview'
            },
            {
              title: '状态',
              dataIndex: 'status',
              key: 'status'
            },
            {
              title: '创建日期',
              dataIndex: 'createDate',
              key: 'createDate'
            }
          ]}
          dataSource={[
            {
              index: 1,
              name: 'WTA即时排名',
              range: 'www.rank-tennis.com/*/rank/wta/s/year',
              preview: 'https://www.rank-tennis.com/zh/rank/wta/s/year',
              status: '统计中',
              createDate: '2017-12-27'
            },
            {
              index: 2,
              name: 'h2h',
              range: 'www.rank-tennis.com/*/h2h',
              preview: 'https://www.rank-tennis.com/zh/h2h',
              status: '统计中',
              createDate: '2017-12-27'
            },
            {
              index: 3,
              name: '首页',
              range: 'www.rank-tennis.com/*/home',
              preview: 'https://www.rank-tennis.com/zh/home',
              status: '统计中',
              createDate: '2017-12-27'
            }
          ]}
          size="small"
          bordered
          pagination={false}
        ></Table>
      </Card>
    </>
  )
};

export default PageClickImage;