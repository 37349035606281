import { useState, useEffect } from "react";
import { Card, Button, Input, Space, Table } from "antd";
function FinancialReturn() {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = generateData(1088);
    setTableData(data);
  },[])

  // 生成数据的函数
  const generateData = (count) => {
    // 根据表头字段生成数据，要求每条数据都不一样。
    // 要求：1、合同编号从HT20210701001开始，每次递增1。2、日期从2021-07-01开始，每次递增1天。3、公司名称从公司名称1开始，每次递增1。4、总金额从1000开始，每次递增1000。5、退款金额从0开始，每次递增1000。6、收款单位从收款单位1开始，每次递增1。7、类型有物业费收入、空调费收入、租金收入。8、起止时间从2021-07-01至2021-07-31。9、退款时间为空。10、退款状态有未退款、已退款。11、备注从备注1开始，每次递增1。
    const data = [];
    for (let i = 0; i < count; i++) {
      data.push({
        key: i + 1,
        contractNumber: `HT20210711${i + 1}`,
        date: `2022-${Math.floor(Math.random() * 12 + 1)}-${Math.floor(Math.random() * 28 + 1)}`,
        companyName: `公司名称${i + 1}`,
        amount: 1000 * (i + 1),
        refundAmount: 0,
        payee: `收款单位${i + 1}`,
        type: ['物业费收入', '空调费收入', '租金收入'][i % 3],
        timeRange: '2021-07-01至2021-07-31',
        refundTime: '',
        refundStatus: ['未退款', '已退款'][i % 2],
        remark: `备注${i + 1}`
      });
    }
    return data;
  };

  return (
    <>
      <Card
        title={
          <Button type="primary">新增回款</Button>
        }
        extra={
          <>
            <Space>
              <Input placeholder="请输入搜索内容" />
              <Button type="primary">导出</Button>
            </Space>
          </>
        }
      >
        <Table
          columns={[
            {
              title: '合同编号',
              dataIndex: 'contractNumber',
              key: 'contractNumber',
            },
            {
              title: '日期',
              dataIndex: 'date',
              key: 'date',
            },
            {
              title: '公司名称',
              dataIndex: 'companyName',
              key: 'companyName',
            },
            {
              title: '总金额/退款金额',
              key: 'amount',
              render: (text, record) => (
                <span>{record.amount} / {record.refundAmount}</span>
              )
            },
            {
              title: '收款单位',
              dataIndex: 'payee',
              key: 'payee',
            },
            {
              title: '类型', // 物业费收入、空调费收入、租金收入
              dataIndex: 'type',
              key: 'type',
            },
            {
              title: '起止时间',
              dataIndex: 'timeRange',
              key: 'timeRange',
            },
            {
              title: '退款时间',
              dataIndex: 'refundTime',
              key: 'refundTime',
            },
            {
              title: '退款状态', // 未退款、已退款
              dataIndex: 'refundStatus',
              key: 'refundStatus',
            },
            {
              title: '备注',
              dataIndex: 'remark',
              key: 'remark',
            },
            {
              title: '操作',
              key: 'operation',
              render: (text, record) => (
                <Space>
                  <Button type="primary">退款</Button>
                  <Button type="primary" danger>删除</Button>
                </Space>
              )
            }
          ]}
          dataSource={tableData}
          pagination={{
            defaultPageSize: 50
          }}
        ></Table>
      </Card>
    </>
  );
};

export default FinancialReturn;