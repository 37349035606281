import * as echarts from 'echarts';
import { useEffect, useRef, useState } from 'react';
import { Card, Row, Col, Tabs, Table } from "antd";
import HomeQueryForm from '@/components/home-query-form';
const { Meta } = Card;
function TransformationOverview() {
  const lineRef = useRef(null);
  const [tabsActive, setTabsActive] = useState('1');

  useEffect(() => {
    const lineInstance = echarts.init(lineRef.current);

    initLineChart(lineInstance);

    const resizeObserver = new ResizeObserver(() => {
      lineInstance.resize();
    });
    resizeObserver.observe(lineRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      lineInstance.dispose(); // 销毁ECharts实例  
    };
  }, []);

  const initLineChart = (chartInstance) => {
    chartInstance.setOption({
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        left: '0%',
        right: '0%',
        top: '10%',
        bottom: '10%',
        containLabel: true
      },
      legend: {
        data: ['转化次数', '转化率'],
        bottom: 0,
        left: 'center'
      },
      series: [
        {
          name: '转化次数',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'line'
        },
        {
          name: '转化率',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'line'
        },
      ]
    });
  }
  return (
    <div>
      <Card title="转化概况">
        <HomeQueryForm />
        <Row gutter={20} style={{ marginTop: 30 }}>
          <Col offset={2} span={4}>
            <Card bordered={false}>
              <Meta title="访问次数" description="0" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="访客数（UV）" description="0" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="转化次数" description="--" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="转化率" description="--" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="转化收益" description="0" />
            </Card>
          </Col>
        </Row>

        <div ref={lineRef} className='chart-line' style={{ height: 305 }}></div>

        <Tabs
          style={{ marginTop: 30 }}
          type='card'
          defaultActiveKey='1'
          onChange={(key) => {
            setTabsActive(key);
          }}
          items={[
            { label: '页面转化', key: '1' },
            { label: '事件转化', key: '2' },
            { label: '时长转化', key: '3'},
            { label: '页数转化', key: '4' },
          ]}
        ></Tabs>

        {
          tabsActive === '1' && (
            <Table
              size='small'
              bordered
              columns={[
                {
                  title: '页面目标名称',
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center'
                },
                {
                  title: '页面网址',
                  dataIndex: 'url',
                  key: 'url',
                  align: 'center'
                },
                {
                  title: '转化次数',
                  dataIndex: 'conversionCount',
                  key: 'conversionCount',
                  align: 'center'
                },
                {
                  title: '转化率',
                  dataIndex: 'conversionRate',
                  key: 'conversionRate',
                  align: 'center'
                },
                {
                  title: '环比变化',
                  dataIndex: 'linkRelativeChange',
                  key: 'linkRelativeChange',
                  align: 'center'
                },
                {
                  title: '转化收益',
                  dataIndex: 'conversionIncome',
                  key: 'conversionIncome',
                  align: 'center'
                }
              ]}
            ></Table>
          )
        }
        {
          tabsActive === '2' && (
            <Table
              size='small'
              bordered
              columns={[
                {
                  title: '事件目标名称',
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center'
                },
                {
                  title: '添加方式',
                  dataIndex: 'url',
                  key: 'url',
                  align: 'center'
                },
                {
                  title: '事件所在页面集',
                  dataIndex: 'conversionCount',
                  key: 'conversionCount',
                  align: 'center'
                },
                {
                  title: '转化次数',
                  dataIndex: 'conversionRate',
                  key: 'conversionRate',
                },
                {
                  title: '转化率',
                  dataIndex: 'conversionRate',
                  key: 'conversionRate',
                  align: 'center'
                },
                {
                  title: '环比变化',
                  dataIndex: 'linkRelativeChange',
                  key: 'linkRelativeChange',
                  align: 'center'
                },
                {
                  title: '转化收益',
                  dataIndex: 'conversionIncome',
                  key: 'conversionIncome',
                  align: 'center'
                }
              ]}
            ></Table>
          )
        }
        {
          tabsActive === '3' && (
            <Table
              size='small'
              bordered
              columns={[
                {
                  title: '时长',
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center'
                },
                {
                  title: '转化次数',
                  dataIndex: 'conversionRate',
                  key: 'conversionRate',
                },
                {
                  title: '转化率',
                  dataIndex: 'conversionRate',
                  key: 'conversionRate',
                  align: 'center'
                },
                {
                  title: '环比变化',
                  dataIndex: 'linkRelativeChange',
                  key: 'linkRelativeChange',
                  align: 'center'
                },
                {
                  title: '转化收益',
                  dataIndex: 'conversionIncome',
                  key: 'conversionIncome',
                  align: 'center'
                }
              ]}
            ></Table>
          )
        }
        {
          tabsActive === '4' && (
            <Table
              size='small'
              bordered
              columns={[
                {
                  title: '访问页数',
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center'
                },
                {
                  title: '转化次数',
                  dataIndex: 'conversionRate',
                  key: 'conversionRate',
                },
                {
                  title: '转化率',
                  dataIndex: 'conversionRate',
                  key: 'conversionRate',
                  align: 'center'
                },
                {
                  title: '环比变化',
                  dataIndex: 'linkRelativeChange',
                  key: 'linkRelativeChange',
                  align: 'center'
                },
                {
                  title: '转化收益',
                  dataIndex: 'conversionIncome',
                  key: 'conversionIncome',
                  align: 'center'
                }
              ]}
            ></Table>
          )
        }
      </Card>
    </div>
  );
};

export default TransformationOverview;