import React from "react";

import StateStore from "./state.store";

class RootStore {
  constructor() {
    this.stateStore = new StateStore(this);
  }
}

const context = React.createContext(new RootStore());
const useStore = () => React.useContext(context);

export default useStore;
