import { Card } from 'antd';
import HomeQueryForm from '@/components/home-query-form';
import HomeComponent from '@/components/home-component';
function EntryPage() {
  return (
    <>
      <Card title="入口页面">
        <HomeQueryForm />
        <HomeComponent />
      </Card>
    </>
  )
};

export default EntryPage;