import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import './layout.scss';

import Logo from './logo';
import MenuComponent from './menu';

import HeaderComponent from './header';

const { Header, Sider, Content } = Layout;

function LayoutComponent() {
  return (
    <>
      <Layout>
        <Sider 
          className="layout-sider-wrap" 
          width={240}
        >
          <Logo />
          <MenuComponent />
        </Sider>
        <Content>
          <Layout>
            <Header className="layout-header-wrap">
              <HeaderComponent />
            </Header>
            <Content className="layout-content-inner">
              <Outlet />
            </Content>
          </Layout>
        </Content>
      </Layout>
    </>
  )
};

export default observer(LayoutComponent);