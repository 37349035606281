import { Card, Table, Button, Row, Col } from "antd";
const { Meta } = Card;
function FinancialManagement() {
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: '流水编号',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: '流水类型',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '流水金额',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Button type="link">查看详情</Button>
      ),
    }
  ]
  const dataSource = [
    {
      id: 1,
      serialNumber: '202107010001',
      type: '收入',
      amount: '1000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 2,
      serialNumber: '202107010002',
      type: '支出',
      amount: '500',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 3,
      serialNumber: '202107010003',
      type: '收入',
      amount: '2000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 4,
      serialNumber: '202107010004',
      type: '支出',
      amount: '1000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 5,
      serialNumber: '202107010005',
      type: '收入',
      amount: '3000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 6,
      serialNumber: '202107010006',
      type: '支出',
      amount: '2000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 7,
      serialNumber: '202107010007',
      type: '收入',
      amount: '4000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 8,
      serialNumber: '202107010008',
      type: '支出',
      amount: '3000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 9,
      serialNumber: '202107010009',
      type: '收入',
      amount: '5000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 10,
      serialNumber: '202107010010',
      type: '支出',
      amount: '4000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 11,
      serialNumber: '202107010011',
      type: '收入',
      amount: '6000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 12,
      serialNumber: '202107010012',
      type: '支出',
      amount: '5000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 13,
      serialNumber: '202107010013',
      type: '收入',
      amount: '7000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 14,
      serialNumber: '202107010014',
      type: '支出',
      amount: '6000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 15,
      serialNumber: '202107010015',
      type: '收入',
      amount: '8000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 16,
      serialNumber: '202107010016',
      type: '支出',
      amount: '7000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 17,
      serialNumber: '202107010017',
      type: '收入',
      amount: '9000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 18,
      serialNumber: '202107010018',
      type: '支出',
      amount: '8000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 19,
      serialNumber: '202107010019',
      type: '收入',
      amount: '10000',
      time: '2024-07-01 12:00:00'
    },
    {
      id: 20,
      serialNumber: '202107010020',
      type: '支出',
      amount: '9000',
      time: '2024-07-01 12:00:00'
    },
  ]
  return (
    <div>
      <Row gutter={[40, 40]}>
        <Col span={6}>
          <Card>
            <Meta title="总收入" description="317,751.14" />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Meta title="总支出" description="61,157.48" />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Meta title="总利润" description="256,593.66" />
          </Card>
        </Col>
        <Col span={24}>
          <Card title="财务流水">
            <Table
              columns={columns}
              dataSource={dataSource}
              bordered
              size="small"
              pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共 ${total} 条`,
                pageSizeOptions: ['10', '20', '30', '40'],
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FinancialManagement;