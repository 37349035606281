import { Card, Tabs, Row, Col, Table } from 'antd';
import HomeQueryForm from '@/components/home-query-form';

const { Meta } = Card;
function InterviewedPage() {
  return (
    <>
      <Card title="受访页面">
        <HomeQueryForm />

        <Tabs
          style={{ marginTop: 20 }}
          defaultActiveKey="1"
          type="card"
          size="small"
          items={[
            { label: '指标概览', key: '1' },
            { label: '页面价值分析', key: '2' },
            { label: '入口页分析', key: '3' },
            { label: '退出页分析', key: '4' },
          ]}
        ></Tabs>

        <Row gutter={20} style={{ marginTop: 30 }}>
          <Col offset={2} span={4}>
            <Card bordered={false}>
              <Meta title="浏览量（PV）" description="96,337" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="访客数（UV）" description="79,697" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="贡献下游浏览量" description="19,261" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="退出页次数" description="46,591" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="平均停留时长" description="00:00:52" />
            </Card>
          </Col>
        </Row>

        <Table
          size='small'
          bordered
          columns={[
            {
              title: '',
              dataIndex: 'index',
              key: 'index',
              render: (text, record, index) => index + 1
            },
            {
              title: '页面URL',
              dataIndex: 'url',
              key: 'url',
              width: 930
            },
            {
              title: '网站基础指标',
              children: [
                {
                  title: '浏览量（PV）',
                  dataIndex: 'pv',
                  key: 'pv'
                },
                {
                  title: '访客数（UV）',
                  dataIndex: 'uv',
                  key: 'uv'
                }
              ]
            },
            {
              title: '流量质量指标',
              children: [
                {
                  title: '贡献下流浏览量',
                  dataIndex: 'contribute',
                  key: 'contribute'
                },
                {
                  title: '退出页次数',
                  dataIndex: 'exit',
                  key: 'exit'
                },
                {
                  title: '平均停留时长',
                  dataIndex: 'stay',
                  key: 'stay'
                }
              ]
            }
          ]}
          dataSource={[
            {
              id: 1,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 1000,
              uv: 800,
              contribute: 200,
              exit: 500,
              stay: '00:01:00'
            },
            {
              id: 2,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 2000,
              uv: 1500,
              contribute: 500,
              exit: 1000,
              stay: '00:02:00'
            },
            {
              id: 3,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 3000,
              uv: 2000,
              contribute: 800,
              exit: 1500,
              stay: '00:03:00'
            },
            {
              id: 4,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 4000,
              uv: 2500,
              contribute: 1000,
              exit: 2000,
              stay: '00:04:00'
            },
            {
              id: 5,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 5000,
              uv: 3000,
              contribute: 1200,
              exit: 2500,
              stay: '00:05:00'
            },
            {
              id: 6,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 6000,
              uv: 3500,
              contribute: 1500,
              exit: 3000,
              stay: '00:06:00'
            },
            {
              id: 7,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 7000,
              uv: 4000,
              contribute: 1800,
              exit: 3500,
              stay: '00:07:00'
            },
            {
              id: 8,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 8000,
              uv: 4500,
              contribute: 2000,
              exit: 4000,
              stay: '00:08:00'
            },
            {
              id: 9,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 9000,
              uv: 5000,
              contribute: 2200,
              exit: 4500,
              stay: '00:09:00'
            },
            {
              id: 10,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 10000,
              uv: 5500,
              contribute: 2500,
              exit: 5000,
              stay: '00:10:00'
            },
            {
              id: 11,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 11000,
              uv: 6000,
              contribute: 2800,
              exit: 5500,
              stay: '00:11:00'
            },
            {
              id: 12,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 12000,
              uv: 6500,
              contribute: 3000,
              exit: 6000,
              stay: '00:12:00'
            },
            {
              id: 13,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 13000,
              uv: 7000,
              contribute: 3200,
              exit: 6500,
              stay: '00:13:00'
            },
            {
              id: 14,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 14000,
              uv: 7500,
              contribute: 3500,
              exit: 7000,
              stay: '00:14:00'
            },
            {
              id: 15,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 15000,
              uv: 8000,
              contribute: 3800,
              exit: 7500,
              stay: '00:15:00'
            },
            {
              id: 16,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 16000,
              uv: 8500,
              contribute: 4000,
              exit: 8000,
              stay: '00:16:00'
            },
            {
              id: 17,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 17000,
              uv: 9000,
              contribute: 4200,
              exit: 8500,
              stay: '00:17:00'
            },
            {
              id: 18,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 18000,
              uv: 9500,
              contribute: 4500,
              exit: 9000,
              stay: '00:18:00'
            },
            {
              id: 19,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 19000,
              uv: 10000,
              contribute: 4800,
              exit: 9500,
              stay: '00:19:00'
            },
            {
              id: 20,
              url: 'http://ty-platform.cmvstv.com/navigate/overview/index?siteId=12741553&siteTime=1617235200&userId=1#/overview/summary',
              pv: 20000,
              uv: 10500,
              contribute: 5000,
              exit: 10000,
              stay: '00:20:00'
            }
          ]}
          pagination={{
            defaultPageSize: 20, 
          }}
        ></Table>
      </Card>
    </>
  )
};

export default InterviewedPage;