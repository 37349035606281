import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from "antd";
import locale from "antd/locale/zh_CN";
import 'antd/dist/reset.css';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import './index.css';

dayjs.locale('zh-cn');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    locale={locale} 
    theme={{
      token: {
        colorPrimary: '#804EFD'
      },

    }}
  >
    <App />
  </ConfigProvider>
);