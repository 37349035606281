import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import { Card, Col, Row, Table } from 'antd';
const { Meta } = Card;
function HomeComponent() {
  const chartRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);
    const lineInstance = echarts.init(lineRef.current);

    initChart(chartInstance);
    initLineChart(lineInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
      lineInstance.resize();
    });
    resizeObserver.observe(chartRef.current);
    resizeObserver.observe(lineRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
      lineInstance.dispose(); // 销毁ECharts实例
    };
  }, []);


  const initChart = (chartInstance) => {
    chartInstance.setOption({
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          data: [
            { value: 1048, name: '搜索引擎' },
            { value: 735, name: '外部链接' },
            { value: 580, name: '直接访问' },
            { value: 44, name: '自定义来源' },
          ]
        }
      ]
    });
  };
  const initLineChart = (chartInstance) => {
    chartInstance.setOption({
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        left: '0%',
        right: '0%',
        top: '10%',
        bottom: '10%',
        containLabel: true
      },
      legend: {
        data: ['搜索引擎', '外部链接', '直接访问', '自定义来源'],
        bottom: 0,
        left: 0
      },
      series: [
        {
          name: '搜索引擎',
          data: [36096, 24772, 15873, 10465, 8328, 9031, 10642, 13537, 20091, 25128, 27234, 25898, 27751, 34369, 4561, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'line'
        },
        {
          name: '外部链接',
          data: [8346, 6328, 3932, 2766, 1966, 2415, 2444, 3600, 6121, 8659, 9712, 9224, 9196, 10151, 1439, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'line'
        },
        {
          name: '直接访问',
          data: [26047, 17720, 12094, 8507, 7676, 8721, 11854, 15357, 28142, 39010, 36511, 34191, 30182, 34442, 4794, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'line'
        },
        {
          name: '自定义来源',
          data: [154, 89, 46, 28, 47, 49, 60, 101, 221, 271, 374, 350, 305, 291, 48, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'line'
        }
      ]
    });
  }
  return (
    <>
      <div>
        <Row gutter={20} style={{ marginTop: 30 }}>
          <Col offset={2} span={4}>
            <Card bordered={false}>
              <Meta title="浏览量（PV）" description="850,939" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="访客数（UV）" description="413,058" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="IP数" description="410,651" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="跳出率" description="73.9%" />
            </Card>
          </Col>
          <Col span={4}>
            <Card bordered={false}>
              <Meta title="平均访问时长" description="00:02:50" />
            </Card>
          </Col>
        </Row>

        <div>
          <Row gutter={40}>
            <Col span={8}>
              <div ref={chartRef} className='chart-pie' style={{ height: 305 }}></div>
            </Col>
            <Col span={16}>
              <div ref={lineRef} className='chart-line' style={{ height: 305 }}></div>
            </Col>
          </Row>
          <Table
            size="small"
            bordered
            rowKey="id"
            pagination={false}
            style={{ marginTop: 20 }}
            columns={[
              {
                title: '序号',
                dataIndex: 'id',
                key: 'id'
              },
              {
                title: '来源类型',
                dataIndex: 'sourceType',
                key: 'sourceType'
              },
              {
                title: '网站基础指标',
                children: [
                  {
                    title: '浏览量（PV）',
                    dataIndex: 'pv',
                    key: 'pv'
                  },
                  {
                    title: '访客数（UV）',
                    dataIndex: 'uv',
                    key: 'uv'
                  },
                  {
                    title: 'IP数',
                    dataIndex: 'ip',
                    key: 'ip'
                  },
                ]
              },
              {
                title: '流量质量指标',
                children: [
                  {
                    title: '跳出率',
                    dataIndex: 'bounceRate',
                    key: 'bounceRate'
                  },
                  {
                    title: '平均访问时长',
                    dataIndex: 'avgVisitTime',
                    key: 'avgVisitTime'
                  }
                ]
              }
            ]}
            dataSource={[
              {
                id: 1,
                sourceType: '搜索引擎',
                pv: 36096,
                uv: 8346,
                ip: 26047,
                bounceRate: '73.9%',
                avgVisitTime: '00:02:50'
              },
              {
                id: 2,
                sourceType: '外部链接',
                pv: 24772,
                uv: 6328,
                ip: 17720,
                bounceRate: '73.9%',
                avgVisitTime: '00:02:50'
              },
              {
                id: 3,
                sourceType: '直接访问',
                pv: 15873,
                uv: 3932,
                ip: 12094,
                bounceRate: '73.9%',
                avgVisitTime: '00:02:50'
              },
              {
                id: 4,
                sourceType: '自定义来源',
                pv: 10465,
                uv: 2766,
                ip: 8507,
                bounceRate: '73.9%',
                avgVisitTime: '00:02:50'
              },
              {
                id: 5,
                sourceType: '其他',
                pv: 10465,
                uv: 2766,
                ip: 8507,
                bounceRate: '73.9%',
                avgVisitTime: '00:02:50'
              }
            ]}
          ></Table>
        </div>
      </div>
    </>
  )
};

export default HomeComponent;