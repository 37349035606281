import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';

function TrendChart() {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [])

  const initChart = (chartInstance) => {
    chartInstance.setOption({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['今日浏览量(PV)', '昨日浏览量(PV)']
      },
      xAxis: {
        type: 'category',
        data: ['0', '1', '2','3','4','5','6','7', '8','9','10','11', '12','13','14','15', '16','17','18','19', '20','21','22','23']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '今日浏览量(PV)',
          data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          type: 'line'
        },
        {
          name: '昨日浏览量(PV)',
          data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          type: 'line'
        }
      ]
    });
  }
  return (
    <>
      <div ref={chartRef} className='chart-base' style={{height: 412}}></div>
    </>
  )
};

export default TrendChart;