import { useState, useEffect } from "react";
import { Card, Button, Table, Input, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function FinancialEIIM() {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = generateData(178);
    setTableData(data);
  }, []);

  // 生成开票信息数据的函数
  const generateData = (count) => {
    // 根据表头字段生成数据，要求每条数据都不一样。
    // 要求：1、合同号从HT20210701001开始，每次递增1。2、单位名称从单位名称1开始，每次递增1。3、开户行从开户行1开始，每次递增1。4、银行账号从银行账号1开始，每次递增1。5、开户行地址从开户行地址1开始，每次递增1。6、纳税人识别号从纳税人识别号1开始，每次递增1。7、电话从电话1开始，每次递增1。
    const data = [];
    for (let i = 0; i < count; i++) {
      data.push({
        id: i + 1,
        contractNo: `HT2021070100${i + 1}`,
        companyName: `单位名称${i + 1}`,
        bank: `开户行${i + 1}`,
        account: `银行账号${i + 1}`,
        bankAddress: `开户行地址${i + 1}`,
        taxpayerNo: `纳税人识别号${i + 1}`,
        // 随机正确的电话号码
        phone: `1${Math.random().toString().slice(2, 12)}`
      });
    }
    return data;
  }

  return (
    <>
      <Card
        title="企业开票信息管理"
        extra={
          <Space>
            <Input placeholder="输入合同号/单位名称" />
            <Button type="primary">搜索</Button>
            <Button icon={<PlusOutlined />}>添加开票信息</Button>
          </Space>
        }
      >
        <Table
          columns={[
            {
              title: '序号',
              dataIndex: 'id',
              key: 'id'
            },
            {
              title: '合同号',
              dataIndex: 'contractNo',
              key: 'contractNo'
            },
            {
              title: '单位名称',
              dataIndex: 'companyName',
              key: 'companyName'
            },
            {
              title: '开户行',
              dataIndex: 'bank',
              key: 'bank'
            },
            {
              title: '银行账号',
              dataIndex: 'account',
              key: 'account'
            },
            {
              title: '开户行地址',
              dataIndex: 'bankAddress',
              key: 'bankAddress'
            },
            {
              title: '纳税人识别号',
              dataIndex: 'taxpayerNo',
              key: 'taxpayerNo'
            },
            {
              title: '电话',
              dataIndex: 'phone',
              key: 'phone'

            },
            {
              title: '操作',
              key: 'action',
              render: (text, record) => (
                <Space size="middle">
                  <a>编辑</a>
                </Space>
              )
            }
          ]}
          dataSource={tableData}
          pagination={{ pageSize: 20 }}
        ></Table>
      </Card>
    </>
  );
};

export default FinancialEIIM;