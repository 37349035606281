import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { getStorage } from '@/utils';

function AuthComponent({ children }) {
  if (getStorage()) {
    return <>{children}</>
  } else {
    return <Navigate to="/login" replace />;
  }
}

export default observer(AuthComponent);
